import React from 'react'
import  './footer.css';

export default function Footer() {
    return (
        <div className='footer'>
            <h5>Powerd by GIKPS</h5> 
            <p className="gnu"> &copy; ? Naaa! You are free to use and distribute as much as you want</p> 
        </div>
    )
}
