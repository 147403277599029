import './message.css';

import React from 'react'
import Description from '../description/Description';


function Message() {
    return (
        < >
            <div className="wrapper">
                <Description />
            </div>
        </>
    )
}

export default Message